import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import noImage from '../../../assets/img/no_image.jpeg';
import Label from "./Label";


function PartnerSelect({ type, onChange }) {//type - перевозчик, власник вантажу

  const [partners, setPartners] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const auth = useAuthUser();
  const company_id = auth?.company_id;
  const authToken = useAuthHeader();

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serverUrl}/axios/assets/partners/fetchPartners.php`,
        { company_id: company_id, assets: type },
        headers
      )
      .then((response) => {
        console.log(response);
        const data = response.data;
        setPartners(data);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }, [company_id]);

  const getOptionLabel = (option) => {
   
      return option.name; // Наприклад, ім'я транспортного засобу
   
  };

  const SelectedItem = () => {

   
      return selectedElement ? (
        selectedElement.avatar === "1"? (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <img
              src={
                config.serverUrl +
                "/images/partners/" +
                selectedElement.partner_id +
                ".jpeg"
              }
              alt="avatar"
              style={{ borderRadius: "8px", height: "64px" }}
            />
            <Typography variant="h4">
              {selectedElement?.name}
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <img
              src={noImage}
              alt="no_avatar"
              style={{ borderRadius: "4px", height: "64px" }}
            />
            <Typography>
              {selectedElement?.name}
            </Typography>
          </Stack>
        )
      ) : null;
  };

  const handleChange = (event, newValue) => {
    setSelectedElement(newValue);
    onChange(newValue);
  };

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <Autocomplete
        options={partners} 
        getOptionLabel={(option) => getOptionLabel(option)}
        onChange={handleChange}
        key={(option, index) => index}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Введіть або виберіть контрагента"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
      <SelectedItem />
    </Stack>      




  );
}

export default PartnerSelect;
