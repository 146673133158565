import AssetCard from "../AssetCard";

function DriverDetail({ data, type }) {
  return (
    <>
{data && Object.entries(data).map(([key, value]) => (
  <p key={key}>
    {key}: {value}
  </p>
))}
    </>
  );
}

export default DriverDetail;
