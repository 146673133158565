import { Button, Typography } from "@mui/material";
import { TextInput, PhoneInput } from "../../components/CustomUI/CustomInputs";
import axios from "axios";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import AvatarUploader from "../../components/Uploader/AvatarUploader";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { useState } from "react";
import { useUI } from "../../context/CustomIUProvider";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Label from "../../components/CustomUI/CustomInputs/Label";

function AddPartner() {
  const { showSnackbar } = useUI();

  const auth = useAuthUser();
  const company_id = auth?.company_id;
  const authToken = useAuthHeader();

  const uploadDocsTypes = [
    { title: "driverLicence" },
    { title: "driverPassport" },
  ];

  const formik = useFormik({
    initialValues: {
      companyName: "",
      director: "",
      phones: [],
      email: "",
      address: "",
      site: "",
    },
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });
  const [phoneNumbers, setPhoneNumbers] = useState([
    { value: "", isValid: false },
  ]);

  const handlePhoneChange = (value, index) => {
    const isValid = value.match(/^\+?[1-9]\d{1,14}$/); // Simple validation for international phone numbers
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index] = { value, isValid: !!isValid };

    if (isValid && index === phoneNumbers.length - 1) {
      newPhoneNumbers.push({ value: "", isValid: false });
    }

    setPhoneNumbers(newPhoneNumbers);
  };

  const handleSubmitForm = (values) => {
    values.company = company_id;
    console.log(values);
    console.log(authToken);
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${config.serverUrl}/axios/assets/saveDriver.php`, headers)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            showSnackbar(t(error.response.data.message), "error");
          } else if (error.response.status === 500) {
            showSnackbar(t("errors.server"), "error");
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      });
  };

  const handleAvatarUpload = (avatar) => {
    formik.setFieldValue("avatar", avatar);
  };

  const { t } = useTranslation();
  return (
    <>
      <Grid  container>
        <Grid xs>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} sx={{ px: 3 }}>
              <Grid xs={12} md="auto">
                <Typography sx={{ textAlign: "center" }} variant="h6">
                  {t("general.logo")}
                </Typography>
                <AvatarUploader onAvatarUpload={handleAvatarUpload} />
              </Grid>

              <Grid xs={12} md>
                <Label label={t("assets.companies.name")} />
                <TextInput
                  name="companyName"
                  placeholder="Ввсести"
                  onChange={(e) => formik.handleChange(e)}
                />
                <TextInput
                  label={t("general.firstname")}
                  name="firstname"
                  placeholder={t("general.enter")}
                  onChange={(e) => formik.handleChange(e)}
                />

                {phoneNumbers.map((phoneNumber, index) => (
                  <PhoneInput
                    key={index}
                    onChange={(value) => {
                      handlePhoneChange(value, index);
                    }}
                    label={index ? null : "Telephone"}
                    placeholder={t("general.enter")}
                  />
                ))}
              </Grid>

              <Grid container xs={12} spacing={3} sx={{ px: 2 }}>
                <Grid xs={12}>
                  <Label label={t("assets.companies.employees")} />
                </Grid>
              </Grid>


              <Grid xs={12} sx={{ textAlign: "right", px: 2, py: 3 }}>
                <Button>{t("general.addToDraft")}</Button>

                <Button type="submit" color="secondary" variant="contained">
                  {t("general.saveChanges")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default AddPartner;
