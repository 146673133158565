import { BrowserRouter, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import AuthProvider from "react-auth-kit/AuthProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import createStore from "react-auth-kit/createStore";
import { Box, CssBaseline } from "@mui/material";
import theme from "./theme.js";
import { DrawerProvider } from "./context/SideMenuProvider.jsx";
import { CustomUIProvider } from "./context/CustomIUProvider.jsx";
import AppRouter from "./Routes.jsx";
import SideMenu from "./components/Menu/SideMenu/SideMenu.jsx";

const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "http:",
});

// Компонент Layout для відображення основної структури з SideMenu
const Layout = () => {
  const location = useLocation(); // Отримуємо поточний шлях

  // Масив шляхів, на яких меню не повинно відображатися
  const noSideMenuPages = ["/login", "/register", "/auth"];

  // Перевіряємо, чи поточний шлях є в масиві заборонених
  const hideSideMenu = noSideMenuPages.includes(location.pathname);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexGrow: 1,
        backgroundColor: "#0E1C36",
      }}
    >
      {!hideSideMenu && <SideMenu />} {/* Умовне рендерення меню */}
      <AppRouter />
    </Box>
  );
};

const App = () => {
  return (
    <AuthProvider store={store}>
      <ThemeProvider theme={theme}>
        <CustomUIProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <DrawerProvider>
                <CssBaseline />
                <Layout /> {/* Використовуємо Layout для відображення структури */}
              </DrawerProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </CustomUIProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
