import { Box, Typography, Stack } from "@mui/material";
import config from "../../config";
import noImage from "../../assets/img/no_image.jpeg";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

function AssetThumb({ element, type }) {
  const auth = useAuthUser();
  const company_id = auth.company_id;
  const hasAvatar = element[type + "Avatar"] === "1";
  const imageUrl = hasAvatar
    ? `${config.serverUrl}/images/${company_id}/${type}s/${element[type + "_id"]}.jpeg`
    : noImage;

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <img
        src={imageUrl}
        alt="avatar"
        style={{ borderRadius: "8px", height: "64px" }}
      />
      <Box>
        {type === "driver" ? (
          <Typography variant="h4">
            {`${element?.firstname?.charAt(0)}. ${element?.lastname}`}
          </Typography>
        ) : (
          <>
            <Typography variant="h4">{element[type + "Number"]}</Typography>
            <Typography variant="h5">{element[type + "Name"]}</Typography>
          </>
        )}
      </Box>
    </Stack>
  );
}

export default AssetThumb;
