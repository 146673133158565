import {
  Paper,
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import config from "../../config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import noImg from "../../assets/img/no_image.jpeg";
import { useTheme } from "@mui/material";
import Status from "./Status";

function AssetCard({ type, company, data, onDetail, onDelete }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const Description = ({ type }) => {
    const description =
      type === "vehicle"
        ? [data.vehicleNumber, data.vehicleName].filter(Boolean).join(" / ")
        : type === "trailer"
        ? [data.trailerNumber, data.trailerName].filter(Boolean).join(" / ")
        : data.firstname && data.lastname
        ? `${data.firstname[0]}. ${data.lastname}` // Ім'я з крапкою та прізвище
        : data.lastname
        ? data.lastname // Тільки прізвище
        : data.firstname
        ? data.firstname // Тільки ім'я
        : "";

    return (
      <>
        <Typography variant="caption" sx={{ mt: 1, userSelect: "none" }}>
          {t("general." + type)}:
        </Typography>
        <Typography variant="subtitle2">
          {description ? description : "-"}
        </Typography>
      </>
    );
  };

  const Title = () => {
    return (
        <Grid xs sx={{ ml: 1.5 }}>
          <Typography sx={{ mt: 1 }} variant="h3">
            {data.lastname}
          </Typography>
          <Typography variant="h5" color="grey[500]">
            {data.firstname}
          </Typography>
        </Grid>
    )
  }

  const MoreMenu = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleClickMore = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleDetailClick = (data) => {
      onDetail(data);
      setAnchorEl(null);
    };

    const handleMyAccountClick = () => {
      handleClose();
    };

    const handleDeleteClick = (event, id) => {
      setAnchorEl(null);
      onDelete(id);
    };

    return (
      <>
        <IconButton
          aria-label="more"
          color="primary"
          size="small"
          onClick={handleClickMore}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleDetailClick}>Детально</MenuItem>
          <MenuItem onClick={handleMyAccountClick}>My account</MenuItem>
          <MenuItem onClick={(e) => handleDeleteClick(e, data[type + "_id"])}>
            {t("general.delete")}
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <Paper
      elevation={4}
      sx={{
        width: "300px",
        m: 1,
        p: 1.5,
        color: "#0E1C36",
        backgroundColor: "#F2F2F7",
      }}
    >
      <Grid container>
        <Grid sx={{ width: "64px" }}>
          {data[type+"Avatar"] === "1" ? (
            <img
              src={
                config.serverUrl + "/images/"+company+"/"+type+"s/" + data[type+"_id"] + ".jpeg"
              }
              alt="avatar"
              style={{ borderRadius: "8px", height: "64px" }}
            />
          ) : (
            <img
              src={noImg}
              alt="no_avatar"
              style={{ borderRadius: "4px", width: "100%" }}
            />
          )}
        </Grid>

<Title/>

        <Grid>
          <MoreMenu />
        </Grid>
      </Grid>

      <Paper elevation={0} sx={{ mt: 0.5, p: 1.5 }}>
        <Status status={data[type + "Status"]} />
        <Box>
          {type !== "driver" ? <Description type="driver" /> : null}
          {type !== "vehicle" ? <Description type="vehicle" /> : null}
          {type !== "trailer" ? <Description type="trailer" /> : null}
        </Box>
      </Paper>
    </Paper>
  );
}

export default AssetCard;
