import AssetsPage from "./AssetsPage";
import DriverCard from "../../components/Assets/Drivers/DriverCard";

function Drivers() {
  //const getFilteredData = () => {
  //  if (activeFilters.includes(0)) {
  //    return drivers;
  //  }
  //  return drivers.filter((item) =>
  //    activeFilters.includes(Number(item.driverStatus))
  //  );
  //};




  return (
    <AssetsPage
      type="driver"
    />
  );
}
export default Drivers;
