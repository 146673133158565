import { Box, Drawer, Button, Tooltip, Pagination, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useTranslation } from "react-i18next";
import StatusFilter from "../../components/Assets/StatusFilter";
import Header from "../../components/Layouts/Header/Header";
import SkeletonCard from "../../components/Assets/SkeletonCard";
import useAssets from "../../hooks/useAssets";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import React, { useState } from "react";
import DriverTable from "../../components/Assets/Drivers/DriverTable";
import DriverDetail from "../../components/Assets/Drivers/DriverDetail";
import VehicleCard from "../../components/Assets/VehicleCard";
import TrailerCard from "../../components/Assets/TrailerCard";
import AssetCard from "../../components/Assets/AssetCard";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

function AssetsPage({ type }) {
  const { t } = useTranslation();

  const links = ["drivers", "vehicles", "trailers"];
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("cards"); // 'cards' або 'table'

  const auth = useAuthUser();
  const company_id = auth?.company_id;

  const {
    loading,
    page,
    setPage,
    assets,
    currentAssets,
    statuses,
    detail,
    drawerOpen,
    setDrawerOpen,
    handleDeleteItem,
    handleDetail,
  } = useAssets(type);

  const handleCreateNew = () => {
    navigate("/assets/new-" + type);
  };

  return (
    <>
      <Grid
        container
        sx={{
          width: "100%",
          position: "relative",
          m: 2,
          p: 2,
          borderRadius: "14px",
          background: "#FCFCFC",
          overflow: "auto",
        }}
      >
        <Grid container xs={12} direction="column" sx={{ my: 2 }}>
          <Header
            title={t("pages.assets")}
            activeLink={links.indexOf(type+'s')}
            links={links}
            prefix="/assets"
          />

          <Box sx={{ p: 0.5, mt: 2, backgroundColor: "#f2f2f7" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Tooltip title="add">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleCreateNew}
                >
                  {t("general.create")}
                </Button>
              </Tooltip>

              <Box>
                <Tooltip title="Передгляд в режимі карток">
                  <Button
                    sx={{ minWidth: "30px" }}
                    size="small"
                    onClick={() => setViewMode("cards")}
                  >
                    <GridViewOutlinedIcon
                      color={viewMode === "cards" ? "grey" : "primary"}
                    />
                  </Button>
                </Tooltip>

                <Button
                  sx={{ minWidth: "30px" }}
                  size="small"
                  onClick={() => setViewMode("table")}
                >
                  <TableRowsOutlinedIcon
                    color={viewMode === "table" ? "grey" : "primary"}
                  />
                </Button>
              </Box>

              <StatusFilter type={type} statuses={statuses} />
            </Stack>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Grid container>
              {loading ? (
                Array(5)
                  .fill()
                  .map((_, index) => <SkeletonCard key={index} />)
              ) : viewMode === "cards" ? (
                currentAssets.map((item, index) => {

                  return (

                  <AssetCard
                  data={item}
                  key={index}
                  type={type}
                  company={company_id}
                  onDetail={handleDetail}
                  onDelete={handleDeleteItem}
                />


                  )







                  if (type === "driver") {
                    return (
                      <AssetCard
                        data={item}
                        key={index}
                        type={type} 
                        onDetail={handleDetail}
                        onDelete={handleDeleteItem}
                      />
                    );
                  } else if (type === "vehicle") {
                    return (
                      <VehicleCard
                        data={item}
                        key={index}
                        onDelete={() => handleDeleteItem(item.id)}
                      />
                    );
                  } else if (type === "trailer") {
                    return (
                      <TrailerCard
                        data={item}
                        key={index}
                        onDelete={() => handleDeleteItem(item.id)}
                      />
                    );
                  }
                  return null;
})
              ) : (
                <DriverTable data={assets} />
              )}


            </Grid>
          </Box>
          <Stack spacing={2} alignItems="center" mt={2}>
            <Pagination
              count={Math.ceil(assets.length / 10)}
              page={page}
              onChange={(e, value) => setPage(value)}
              color="primary"
            />
          </Stack>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { background: "white", width: "40%" },
        }}
      >
        <Box p={2} role="presentation" sx={{ color: "grey", height: "100%" }}>
          <DriverDetail type={type} data={detail} />
        </Box>
      </Drawer>
    </>
  );
}

export default AssetsPage;
