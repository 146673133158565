import { Box } from "@mui/material";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../../config";


function ClosestVehicle({initialData}) {
  const authToken = useAuthHeader();



  useEffect(() => {
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serverUrl}/axios/shippings/fetchClosestVehicles.php`,
        initialData,
        headers
      )
      .then((response) => {
        console.log(response.data);
        //setAssets(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [initialData]);




  return (
    <Box>
555
    </Box>

  );
}
export default ClosestVehicle;
