import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography} from "@mui/material";
import { useTranslation } from "react-i18next";

function Label({label, notNecessarily, ...props}) {
const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between" {...props}>
      <Grid sx={{ color: "#0E1C36" }}>
        {label && <Typography variant="h6">{label}</Typography>}
      </Grid>
      <Grid>
        {notNecessarily && (
          <Typography variant="caption">
            {t("general.notNecessarily")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default Label;
