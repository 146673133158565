import { Stack, Typography } from "@mui/material";

import { ReactComponent as RestIcon } from "../../assets/icons/rest.svg";
import { ReactComponent as BlankIcon } from "../../assets/icons/blank.svg";
import { ReactComponent as VehicleIcon } from "../../assets/icons/vehicle.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

function Status({ status, variant }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const color = {
    1: theme.palette.orange.main,
    2: theme.palette.blue.main,
    3: theme.palette.red.main,
  };
  const white = theme.palette.white.main;

  const iconStyle = {
      height: "18px",
      width: "18px",
      fill:  variant === "outlined" ? color[status] : white,
  }

  let statusText = null;
  let statusIcon = null;

  switch (status) {
    case "1":
      statusText = t("general.status.free");
      statusIcon = (
        <BlankIcon
          style={iconStyle}
        />
      );
      break;

    case "2":
      statusText = t("general.status.flight");
      statusIcon = (
        <VehicleIcon
          style={iconStyle}
        />
      );

      break;
    case "3":
      statusText = t("general.status.rest");
      statusIcon = (
        <RestIcon
          style={iconStyle}
        />
      );

      break;

    default:
      break;
  }
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        border: variant === "outlined" ? `solid ${color[status]} 1px` : "none",
        display: "inline-flex",
        width: "auto",
        py: 0.5,
        px: 1,
        borderRadius: "4px",
        backgroundColor: variant === "outlined" ? "none" : color[status],
        color: variant === "outlined" ? color[status] : white,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {statusIcon}

      <Typography variant="body" sx={{ lineHeight: 1 }} component="span">
        {statusText}
      </Typography>
    </Stack>
  );
}

export default Status;
