import AssetsPage from "./AssetsPage";

function Vehicles() {
  return (
    <AssetsPage
      type="vehicle"
    />
  );
}
export default Vehicles;
