import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Header from "../../Layouts/Header/Header";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import config from "../../../config";
import axios from "axios";
import {
  Box,
  Avatar,

} from "@mui/material";

import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Status from "../Status";

function DriverTable({ data }) {
  const auth = useAuthUser();
  const company_id = auth.company_id;
  const authToken = useAuthHeader();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(data);

  
const centerBlockStyle = {
  display: 'flex',
  justifyContent: 'center', 
  alignItems: 'center',
  width: '100%',
  height: '100%',
}

  const columns = [
    {
      field: "avatar",
      headerName: "#",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Box sx={centerBlockStyle}> 
        <Avatar
          src={`${config.serverUrl}/images/${company_id}/drivers/${params.row.driver_id}.jpeg`}
          alt={params.row.name}
        />          
        </Box>

      ),
    },
    {
      field: "fullName",
      headerName: "Full name",
      //description: 'Імя та прізвище водія',
      width: 160,
      valueGetter: (value, row) =>
        `${row.firstname || ""} ${row.lastname || ""}`,
    },
    {
      field: "driversStatus",
      headerName: "Status",
      //description: 'Імя та прізвище водія',
      //sortable: false,
      width: 160,
      renderCell: (params) => (
        <Box sx={centerBlockStyle}> 
        <Status variant="outlined"
          status={params.value}
        />        
        </Box>

      )}
  ];

  return (
    <>
      <Box sx={{ mt: 2, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          //loading={loading}
          getRowId={(row) => row.driver_id} // Вказуємо, як визначати унікальний ID для рядків
        />
      </Box>
    </>
  );
}
export default DriverTable;
