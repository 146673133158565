import {
  Divider,
  IconButton,
  Paper,
  Button,
  Typography,
  Stack,
  InputLabel,
} from "@mui/material";
import {
  TextInput,
  CheckInput,
  DateInput,
  PhoneInput,
  LanguageInput,
  LicenseInput,
  NumberInput,
} from "../../components/CustomUI/CustomInputs";
import axios from "axios";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import AvatarUploader from "../../components/Uploader/AvatarUploader";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import AddSvg from "../../assets/icons/add-btn.svg";
import { useUI } from "../../context/CustomIUProvider";
import DocsUploader from "../../components/Uploader/DocsUploader";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import SelectAsset from "../../components/Assets/SelectAsset";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Label from "../../components/CustomUI/CustomInputs/Label";

function AddTrailer() {
  const navigate = useNavigate();
  const { showSnackbar, showDialog } = useUI();

  const auth = useAuthUser();
  const company_id = auth?.company_id;
  const authToken = useAuthHeader();

  const formik = useFormik({
    initialValues: {
      avatar: null,
      name: "",
      number: "",
      produced: null,
      payload: null,
      driverDefault: null,
      vehicleDefault: null
    },
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values) => {
    values.company = company_id;
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serverUrl}/axios/assets/trailers/addNewTrailer.php`,
        values,
        headers
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            showSnackbar(t(error.response.data.message), "error");
          } else if (error.response.status === 500) {
            showSnackbar(t("errors.server"), "error");
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      });
  };

  const handleDraftClick = () => {
    console.log(formik.values);
  };

  const handleAvatarUpload = (avatar) => {
    formik.setFieldValue("avatar", avatar);
  };

  const hendleSetDefaultVehicle = (data) => {
    formik.setFieldValue("vehicleDefault", data?.vehicle_id);
  };
  const hendleSetDefaultDriver = (data) => {
    formik.setFieldValue("driverDefault", data?.driver_id);
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        sx={{
          width: "100%",
          position: "relative",
          m: 2,
          p: 2,
          borderRadius: "14px",
          background: "#FCFCFC",
          overflow: "auto",
        }}
      >
        <Grid xs={12} container spacing={3}>
          <Grid xs>
            <Paper sx={{ flexGrow: 1 }}>
              <Grid container alignItems="center" sx={{ px: 3, py: 1 }}>
                <Grid xs>
                  <Typography variant="h3" xs={{ color: "primary" }}>
                    {t("assets.drivers.addNewTrailer")}
                  </Typography>
                </Grid>
                <Grid xs="auto">
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Divider />
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3} sx={{ px: 3, pt: 3 }}>
                  <Grid xs={12} md="auto">
                    <AvatarUploader onAvatarUpload={handleAvatarUpload} />
                  </Grid>

                  <Grid xs={12} md>
                    <Label sx={{ mt: 3 }} label= {t("assets.trailers.name")}/>

                    <TextInput
                      name="name"
                      placeholder={t("general.enter")}
                      onChange={(e) => formik.handleChange(e)}
                    />
                    <TextInput
                      label={t("general.number")}
                      name="number"
                      placeholder={t("general.enter")}
                      onChange={(e) => formik.handleChange(e)}
                    />
                    <Label label={t("general.payload")} />
                    <NumberInput
                      name="payload"
                      formik={formik}
                      value={formik.values.payload}
                      //onChange={(e) => formik.handleChange(e)}
                      max={100}
                      min={0}
                      decimalPlaces={0}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="uk"
                    >
                      <Label sx={{ mt: 3 }} label={t("assets.vehicles.produced")} />
                        
                      <DatePicker
                        name="produced"
                        //value = {new Date(formik.values.produced)}
                        onChange={(newValue) =>
                          formik.setFieldValue("produced", newValue.$y)
                        }
                        views={["year"]}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container spacing={3} sx={{ px: 3 }}>
                  <Grid xs={12}>
                    <Typography variant="h4">
                      {t("documents.addTrailerDocs")}
                    </Typography>
                  </Grid>

                  <Grid xs={12} md={6}>
                    <DocsUploader
                      type="other"
                      title={t("documents.other")}
                      company={company_id}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sx={{ textAlign: "right", px: 2, py: 3 }}>
                  <Button onClick={handleDraftClick}>
                    {t("general.addToDraft")}
                  </Button>

                  <Button type="submit" color="secondary" variant="contained">
                    {t("general.saveChanges")}
                  </Button>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid xs="auto">
            <Paper sx={{ p: 2, minWidth: "300px" }}>
              <Stack spacing={2}>
                <Typography variant="h3">
                  {t("general.vehicle") + " / " + "general.driver"}
                </Typography>
                <SelectAsset
                  type="vehicle"
                  onChange={hendleSetDefaultVehicle}
                />
                <SelectAsset
                  type="driver"
                  onChange={hendleSetDefaultDriver}
                />
                {/*<AttachCard itemName="vehicle" onChange={handleAttachCardChange} />
          <AttachCard itemName="trailer" onChange={handleAttachCardChange} />*/}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AddTrailer;
