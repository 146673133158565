import { Stack } from "@mui/material";
import { useState } from "react";
import NumberInput from "./NumberInput";
import { useTranslation } from "react-i18next";

const DimensionsInput = ({ label, onChange, formik, ...props }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" sx={{ mt: 1 }}>
      <NumberInput
        name="height"
        label={t("general.height")}
        formik={formik}
        value={formik.values.height}
        decimalPlaces={3}
        min={0}
        max={50}
        onChange={(e) => formik.handleChange(e)}
        InputLabelProps={{
          shrink: true, // Завжди показувати label зверху
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px 0 0 4px", // Rounded only on the right side
          },
        }}
      />

      <NumberInput
        name="length"
        label={t("general.length")}
        formik={formik}
        value={formik.values.length}
        decimalPlaces={3}
        min={0}
        max={50}
        onChange={(e) => formik.handleChange(e)}
        InputLabelProps={{
          shrink: true, // Завжди показувати label зверху
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "0", // No border radius for middle input
            borderLeft: "solid 1px #fff",
            borderRight: "solid 1px #fff",
          },
        }}
      />

      <NumberInput
        name="width"
        label={t("general.width")}
        formik={formik}
        value={formik.values.width}
        decimalPlaces={3}
        min={0}
        max={50}
        onChange={(e) => formik.handleChange(e)}
        InputLabelProps={{
          shrink: true, // Завжди показувати label зверху
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "0 4px 4px 0", // Rounded only on the right side
          },
        }}
      />
    </Stack>
  );
};

export default DimensionsInput;
