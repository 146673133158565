import { Box, Select, MenuItem, FormControl } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Label from "./Label";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const CargoTypeSelect = ({ label, onChange, ...props }) => {

  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const authToken = useAuthHeader();

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serverUrl}/axios/selects/fetchCargoTypes.php`,
        {},
        headers
      )
      .then((response) => {
        console.log(response);
        setTypes(response.data);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }, []);

  const handleChange = (e) => {
    setType(e.target.value); // Використовуйте e.target.value замість newValue
    onChange(e.target.value); // Тут onChange з великої літери
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Label label={label} />
      <Grid container>
        <Grid xs={12}>
          <FormControl fullWidth>
            <Select
              value={type}
              //label="Age"
              onChange={handleChange}
>
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
              ))
              }
            

            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CargoTypeSelect;
