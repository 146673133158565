import { Box, Button, Stack, Tooltip } from "@mui/material";
import PartnerSelect from "../../components/CustomUI/CustomInputs/PartnerSelect";
import TextInput from "../../components/CustomUI/CustomInputs/TextInput";
import CargoTypeSelect from "../../components/CustomUI/CustomInputs/CargoTypeSelect";
import SwitchInput from "../../components/CustomUI/CustomInputs/SwitchInput";
import DimensionsInput from "../../components/CustomUI/CustomInputs/DimensionsInput ";
import Label from "../../components/CustomUI/CustomInputs/Label";
import { useTranslation } from "react-i18next";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useUI } from "../../context/CustomIUProvider";
import AddPartner from "../Assets/AddPartner";

function AddShippingSt3({ formik }) {
  const onChange = (data) => {
    console.log(data);
  };
  const { closeDialog, showDialog } = useUI();

  const { t } = useTranslation();

  const hendleChangeCargoType = (typeId) => {
    console.log(typeId);
  };

  const hendleCreatePartner = () => {
    showDialog({
      title: "Ствоити нового партнера (контрагента)",
      content: <AddPartner />,
    });
  };

  return (
    <>
      step 3
      <Label label={t("shippings.cargo.owner")} />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <PartnerSelect onChange={onChange} />

        <Tooltip title="Створити нового контрагента" placement="top">
          <Button variant="outlined" onClick={hendleCreatePartner}>
            <AddOutlinedIcon />
            <HandshakeOutlinedIcon />
          </Button>
        </Tooltip>
      </Stack>
      <Label sx={{ mt: 3 }} label={t("shippings.cargo.name")} />
      <TextInput name="nameCargo" />
      <CargoTypeSelect
        name="typeCargo"
        label="Тип вантажу"
        onChange={hendleChangeCargoType}
      />
      <Stack direction="row" spacing={2}>
        <SwitchInput label="Швидкопсувний" name="red" />
        <SwitchInput label="ADR" name="red" />
        <SwitchInput label="Негабарит" name="red" />
        <SwitchInput label="Швидкопсувний" name="red" />
      </Stack>
      <Label label={t("shippings.cargo.dimensions")} />
      <Box sx={{ maxWidth: "300px" }}>
        <DimensionsInput formik={formik} />
      </Box>
    </>
  );
}

export default AddShippingSt3;
